// Global utils
import AuthUtils from 'utils/auth';

// Request utils
import request, {REQUEST_ENV} from 'request';

export const loginUserRequest = async (payload, setState) => {
	// payload: {email, password}
	return request({
		method: 'post',
	  url: `${REQUEST_ENV.SERVICE_API_HOST}/admin/admin-account/login`,
	  data: payload,
	}, setState);
};

export const logoutUserRequest = async setState => {
	return request({
		method: 'post',
		url: `${REQUEST_ENV.SERVICE_API_HOST}/admin/admin-account/logout`,
		headers: {
			Authorization: `Bearer ${AuthUtils.sessionToken}`,
		},
	}, setState);
};

export const updatePasswordRequest = async (payload, setState) => {
	// payload: {oldPassword, newPassword}
	return request({
		method: 'post',
	  url: `${REQUEST_ENV.SERVICE_API_HOST}/admin/admin-account/update/password`,
		headers: {
			Authorization: `Bearer ${AuthUtils.sessionToken}`,
		},
	  data: payload,
	}, setState);
};

export const getAdministratorList = async (options, setState) => {
	// payload: {
	// 	options: {pageNum, pageSize, orderBy, order, filter}
	// }
	return request({
		method: 'post',
		url: `${REQUEST_ENV.SERVICE_API_HOST}/admin/admin-account/list`,
		headers: {
	    Authorization: `Bearer ${AuthUtils.sessionToken}`,
	  },
		data: {
			options: {
				pageNum: 1,
				pageSize: 99999,
				orderBy: 'id',
				order: 'asc',
				...options,
			},
		},
	}, setState);
};

export const createNewAdministrator = async (payload, setState) => {
	// payload: {email, password, role}
	return request({
		method: 'post',
	  url: `${REQUEST_ENV.SERVICE_API_HOST}/admin/admin-account/create`,
		headers: {
			Authorization: `Bearer ${AuthUtils.sessionToken}`,
		},
	  data: payload,
	}, setState);
};

export const resetAdministratorPassword = async (payload, setState) => {
	// payload: {adminId, newPassword}
	return request({
		method: 'post',
	  url: `${REQUEST_ENV.SERVICE_API_HOST}/admin/admin-account/reset/password`,
		headers: {
			Authorization: `Bearer ${AuthUtils.sessionToken}`,
		},
	  data: payload,
	}, setState);
};

export const deactivateAdministrator = async (payload, setState) => {
	// payload: {adminId}
	return request({
		method: 'post',
	  url: `${REQUEST_ENV.SERVICE_API_HOST}/admin/admin-account/deactivate`,
		headers: {
			Authorization: `Bearer ${AuthUtils.sessionToken}`,
		},
	  data: payload,
	}, setState);
};
