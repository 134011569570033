import {lazy, Suspense} from 'react';
import {Route, Routes, BrowserRouter, Navigate} from 'react-router-dom';
import {LoadingOverlay, MantineProvider} from '@mantine/core';
import {NotificationsProvider} from '@mantine/notifications';

// Global components
import ExportModal from 'components/others/Modal/ExportModal';

// Global configs
import mantineTheme from 'config/mantineTheme';
import mantineStyles from 'config/mantineStyles';
import mantineDefaultProps from 'config/mantineDefaultProps';
import routes from 'config/routes';

// Global utils
import crashLogger from 'utils/logger';

const importRetry = async (importFn, retries = 2, interval = 1000) => {
	try {
		return await importFn();
	} catch (error) {
		if (retries) {
			await new Promise(resolve => setTimeout(resolve, interval));
			return importRetry(importFn, retries - 1, interval);
		} else {
			window.location.replace('/');
			crashLogger.error(error);
		}
	}
};

const renderRoutes = routeList => {
	return routeList.map((route, i) => {
		// eslint-disable-next-line react/jsx-max-props-per-line
		let element = route.redirect ? <Navigate to="/" replace /> : route.element;
		if (typeof route.element === 'string') {
			const Lazy = lazy(() => importRetry(() => import(route.element + '/index.jsx')));
			element = (
				<Suspense fallback={<LoadingOverlay visible />}>
					<Lazy />
				</Suspense>
			);
		}
		return (
			<Route
				key={i}
				path={route.path || '*'}
				element={element}
			>
				{route.children && renderRoutes(route.children)}
			</Route>
		);
	});
};

function App() {
	return (
		<MantineProvider
			theme={mantineTheme}
			styles={mantineStyles}
			defaultProps={mantineDefaultProps}
		>
			<NotificationsProvider
				position="top-right"
				className="notification-provider">
				<BrowserRouter>
					<Routes>
						{renderRoutes(routes)}
					</Routes>
				</BrowserRouter>
				<ExportModal ref={ref => window.exportRef = ref} />
			</NotificationsProvider>
		</MantineProvider>
	);
}

export default App;
